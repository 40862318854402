<template>
    <div class="news">
        <div class="tabs">
            
            <div class="tab-names">
                <div class="tab-name" v-for="(item, index) in items" :key="item.id" :class="{'active': index==active}" @click="changeActive(index)">
                    {{item.name}}
                </div>
                <a v-if="active != 0" class="more" @click="gotoList(active)">查看更多></a>
            </div>
           
            <div class="tab-content" v-for="(item, index) in items" :key="item.id" :class="{'active': index==active}" >
                <Info v-if="item.content" :item="item" :breads="breads"></Info>
            </div>
            
            
        </div>
    </div>
</template>

<script>
import Info from '../Info'
export default {
    data(){
        return {
            active: 0
        }
    },
    props:{
        breads:{
            type: Array,
            default: []
        },
        items:{
            type: Array,
            default:[
                // {
                    //  name:'',
                    // id: '',
                    // content:{
                    // "total": 3,
                    // "offset": 7,
                    // "limit": 7,
                    // "page": 1,
                    // "list": [
                    //     {
                    //         "adType": "1518073930328129537",
                    //         "adAbstract": "",
                    //         "adTitle": "重点实验室产出报告",
                    //         "id": "1523927354783608834",
                    //         "adDateForMat": "2022-05-10"
                    //     }]
                    //     }
                // }
                ]
        }
    }, 
    components:{Info},
    
    methods:{
        changeActive: function(index){
            this.active = index;
        },
        gotoList(index){
            const item = this.items[index];
            this.$router.push({name:'Institution', path:'/institution', query: {id: item.id}})
        }
    },
    computed:{
        hasMore(){
            if(this.items && this.items.length > 0){
                const content =  this.items[this.active].content;
                if(content){
                    return content.total > content.limit;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.more{
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: rgba(0,0,0,0.80);
    position: absolute;
    right: 0;
    bottom: 4px;
}
.more:hover{
    color: #c3c3c3;
    cursor: pointer;
}
.news{
    height: 100%;
 
}
.tabs{
    height: 100%;
    display: flex;
    flex-direction: column;
    .tab-names{
        display: flex;
        align-items: flex-end;
        position: relative;
        .tab-name{
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            color: rgba(74,74,74,0.80);
            text-align: center;
            background: #F2F7FA;
            border-right: 1px solid #E7E9EB;
            border-top: 1px solid #E7E9EB;
            border-bottom: 1px solid #E7E9EB;
            line-height: 32px;
            cursor: pointer;
            padding-left: 17px;
            padding-right: 17px;
        }
        .tab-name:first-child{
             border-left: 1px solid #E7E9EB;
        }
        .tab-name:hover{
            background: #E7E9EB;
        }
        .active{
            font-size: 16px;
            line-height: 36px;
            background: #007EEC;
            color: #fff;
        }
        .active:hover{
            background: #007EEC;
        }
        
    }
    .tab-content{
        display: none;
        height: 100%;
    }
    .active{
    
        display: inline-block;
    }
}


</style>


<template>
    <div class="nav">
        <div class="nav-item" v-for="(item, index) in list" :key="item.id" @click="gotoDetail(index)">
            <div class="nav-content">
                <img class="nav-icon" :src="getImg(item.dictVal)"/>
                <div class="nav-info">
                    <div class="nav-title">{{item.dictName}}</div>
                    <div class="nav-num">{{item.typeNum}} {{item.dictVal=='INSTRUMENT_DATA'?'套':'个'}}</div>
                </div>
                <img src="@static/img/nav-arrow.svg" class="nav-arrow"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            list: []
        }
    },
    mounted(){
        this.getList();
    },
    methods:{
        getList(){
            var url = '/gdsti2/web/a/webResource/dataTypelist';
            this.$http.post(url).then(res=>{
                if(res.data.state==0){
                    this.list = res.data.result;
                    for(let i = 0 ; i < this.list.length; i++){
                        const imageLink = this.$getImage(this.list[i].dictIcon);
                        this.list[i]['iconUrl'] = imageLink;
                    }
                    // console.log(this.list);
                }
            }).catch(err=>{

            });
        },
        gotoDetail(index){
            const item = this.list[index];

            this.$router.push({name:'TechRes', path: '/techRes', query: {id: item.dictVal, name: item.dictName}});
        },
        getImg(dictVal){
            return "../../static/img/resource/" + dictVal+ ".svg";
        }
    }
}
</script>

<style lang="less" scoped>
.nav{
    display: inline-block;
    width: 290px;
    background: #FFFFFF;

    .nav-item{
        padding: 0 19px 0 23px;
    }

.nav-item:hover, .nav-item:focus{
    background-color: #eee;
}
    .nav-content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0,0,0,0.10);
        padding-top: 15px;
        padding-bottom: 6px;
        align-items: flex-start;
        cursor: pointer;

        .nav-icon{
            width:32px;
            height:32px;
        }

        .nav-info{
            flex: 1;
            margin-left: 10px;
            margin-right: 10px;

            .nav-title{
                font-family: SourceHanSansCN-Bold;
                font-size: 16px;
                color: #3F3F3F;
                letter-spacing: 0;
                text-align: justify;
                line-height: 16px;
            }
            .nav-num{
                font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                color: rgba(0,0,0,0.60);
                letter-spacing: 0;
                text-align: justify;
                line-height: 14px;
                margin-top: 8px;
            }
        }
    }
    .nav-item:last-child .nav-content{
        border-bottom: none;
    }

}
</style>

